.drop-file-upload-container {
  border: 1px solid rgba(34, 36, 38, .15);
  border-radius: 4px;
  padding: 1rem;
  background-color: rgba(238, 238, 238, 0.288);
  cursor: pointer;
}

.drop-file-upload-files {
  list-style: none;
  padding: 0;

  li {
    .icon {
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}