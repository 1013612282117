@import '../../../assets/styles/variables.scss';

.sys-ticket-details-wrapper {
  flex: 1;
  height: 100%;

  .close-note-container {
    margin: 0.5rem 0;
    direction: rtl;

    .note {
      margin-top: 0.3rem;
      height: 2.6rem;

      input {
        text-align: right;
        line-height: 1rem;
      }
    }
  }

  .sys-ticket-details {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;

    @media (max-width: 767px) {
      flex-direction: column;
    }

    .ticket-main {
      flex: 1;
      display: flex;
      flex-direction: column;

      .header-left {
        border-bottom: 1px solid $lightereGray;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem;
        height: 65px;

        @media (max-width: 767px) {
          border-bottom: unset;
          margin: 0.5rem 0;
          background-color: white;
          border-radius: 6px;
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.05);
          height: unset;
          padding: 1rem;
        }

        .titles {
          h4 {
            margin: 0;

            &.ticket-no {
              font-size: 0.9rem;
              margin-bottom: 0.3rem;
            }
          }
        }

        .header-icon {
          cursor: pointer;

          &:not(last-child) {
            margin-left: 0.8rem;
          }

          &.edit.outline:hover {
            color: $blue !important;
          }

          &.trash.alternate.outline:hover,
          &.bullhorn:hover {
            color: $red !important;
          }
        }
      }

      .ticket-content-height-full {
        height: calc(100% - 65px);
      }

      .ticket-content-height-comment {
        height: calc(100% - 125px);
      }

      .ticket-contents {
        overflow: auto;

        .ticket-customer-info {
          @media (max-width: 767px) {
            margin: 0.5rem 0 1rem;
            background-color: white;
            border-radius: 6px;
            box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.05);
          }
        }

        .comment-box {
          flex: 1;
          display: flex;
          flex-direction: column;
          overflow: auto;

          @media (max-width: 767px) {
            margin: 0.5rem 0 0;
            background-color: white;
            border-radius: 6px 6px 0 0;
            box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.05);
          }

          .comments {
            flex: 1;
            padding: 1rem;
            overflow: auto;
            border-top: 1px solid $lightereGray;
          }
        }
      }

      .comment-inputs {
        display: flex;
        align-items: center;
        position: relative;
        height: 60px;
        border-top: 1px solid $lightereGray;
        padding: 0.8rem;

        @media (max-width: 767px) {
          border-top: unset;
          margin: 0 0 0.5rem 0;
          background-color: white;
          border-radius: 0 0 6px 6px;
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.05);
        }

        .input {
          flex: 1;
          margin: 0 0.8rem;
          border-bottom: 1px solid $lighterGray;

          input {
            height: 2rem;
            text-align: right;
            font-size: 0.9rem;
            width: 0;
          }
        }

        .icon.smile {
          color: grey;
          cursor: pointer;

          &:hover {
            color: rgb(207, 135, 0);
          }
        }

        .icon.cancel {
          color: $red;
          cursor: pointer;

          &:hover {
            color: rgb(209, 79, 79);
          }
        }

        section.emoji-mart {
          position: absolute;
          bottom: 50px;
          left: 50px;
        }

        .send-btn {
          box-shadow: 0 0 8px rgba(0, 77, 139, 0.6);
          transform: rotate(-45deg) scaleX(-1);
        }

        .user-img {
          width: 36px;
          height: 36px;
          object-fit: cover;
          border-radius: 18px;
        }
      }
    }

    .ticket-side {
      display: flex;
      flex-direction: column;
      width: 225px;
      border-left: 1px solid $lightereGray;

      @media (max-width: 767px) {
        width: 100%;
        border-left: unset;
      }

      .header-right {
        padding: 0 0.8rem;
        height: 65px;
        border-bottom: 1px solid $lightereGray;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 767px) {
          border-bottom: unset;
          margin: 0.5rem 0;
          background-color: white;
          border-radius: 6px;
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.05);
        }

        .titles {
          display: flex;
          align-items: center;

          .icon.big {
            margin: 0;
          }

          .status-box {
            margin-right: 0.6rem;

            .title {
              margin: 0;
              font-size: 0.8rem;
            }

            .status {
              font-size: 0.9rem;
              margin: 0;

              &.status-orange {
                color: $orange;
              }

              &.status-green {
                color: $green;
              }

              &.status-blue {
                color: $blue;
              }
            }
          }
        }

        .change-status-icon {
          cursor: pointer;

          &:hover {
            color: $blue;
          }
        }
      }

      .ticket-info {
        flex: 1;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        overflow-y: auto;

        @media (max-width: 767px) {
          margin: 0.5rem 0 1rem;
          background-color: white;
          border-radius: 6px;
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.05);
        }

        .data-box {
          border-right: 2px solid $lighterGray;
          padding: 0.26rem 0.6rem;
          height: max-content;
          transition: border-color 300ms;

          &:hover {
            border-color: $gceDarkBlue;
          }

          .title-container {
            display: flex;
            align-items: baseline;
            color: $gceMutedBlue;

            .icon {
              margin: 0 0 0 0.4rem;
            }

            .title {
              font-size: 0.8rem;
              margin: 0;
              display: inline-block;
            }
          }

          .data {
            font-size: 0.9rem;
            margin: 0.3rem 0 0;
            display: inline-block;

            &.ltr {
              direction: ltr;
            }

            .attachment-path {
              border: 1px solid lightgray;
              margin: 4px 0 4px 4px;
              padding: 4px;
              cursor: pointer;
              border-radius: 4px;
              width: 100%;
              text-align: right;

              &:hover {
                background-color: #eeeeee;
              }
            }
          }
        }

        .assign-team-container {
          // border: 1px solid rgba(34, 36, 38, 0.15);
          // padding: 0 0.5rem 0.5rem;
          // border-radius: 0.4rem;

          .title {
            color: $gceMutedBlue;
            font-size: 0.8rem;
            margin: 0.6rem 0;
          }

          button.ui.button {
            margin: 0.6rem 0 0;
          }
        }
      }
    }
  }
}
