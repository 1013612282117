@import '../../../assets/styles/variables.scss';

.expenses-wrapper {
  padding: 1rem;

  .headerMargin {
    margin-bottom: 2rem;
  }
  .card-icon {
    color: #9f3a38;
    float: left;
  }
  .flex-center {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    > span {
      margin-left: 10px;
    }
  }
  .cash-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .flex-input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    flex: 1;
  }
  .checkbox-field {
    display: flex;
    width: 100%;
    justify-content: unset;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.92857143em;
    font-weight: 700;
    text-transform: none;
    div {
      padding: 0.8rem;
    }
  }
  .flex-data-box {
    display: flex;
    padding-top: 2rem;
  }
}
.expenses-form-view {
  @media print {
    .page-card-container {
      height: 100%;
      font-size: 12px;
    }
  }
  
  .header-wrapper {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    .ui.button > .icon:not(.button) {
      opacity: 1;
      margin: 0;
      padding: 0;
    }
    .ui.button:not(.icon) > .icon:not(.button):not(.dropdown) {
      margin: 0;
    }
  }
  .header {
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .header-title {
      font-weight: bold;
      font-size: 1.3rem;
      line-height: 1.9rem;
    }
  }

  .header-logo {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .segments-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem;
    .single-segment-wrapper {
      flex-grow: 1;
      white-space: nowrap;
    }
  }
  .full-width {
    width: 100%;
  }
  .title-container-att {
    display: flex;
    align-items: baseline;
    color: $gceMutedBlue;

    .icon {
      margin: 0 0 0 0.4rem;
    }

    .title-att {
      margin: 0 0 0.78rem 0;
      display: inline-block;
    }
  }
  .embedded-table {
    .page-card-container {
      padding: 0 !important;
      margin: 0 !important;
      height: 100%;

      .settings-table {
        margin: 0.5rem 0 1rem 0;
      }
    }
  }

  .buttons-container {
    padding: 1rem 0;
  }
  .buttons-container-contents {
    display: flex;
    gap: 0.5rem;
  }

  .fluid-button-margin {
    margin-top: 0.5rem !important;
  }

  .form-columns-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .form-rows-wrapper {
    display: flex;
    flex-direction: column;
  }
  .defualt-margin {
    margin: 1rem !important;
  }
  .two-columns {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    .ui.segment {
      margin: 0;
      flex: 1;
    }
    > div {
      flex: 1;
    }
    .vertical-divider {
      width: 1px;
      background-color: rgb(223, 223, 223);
    }

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      gap: 0;
    }
  }

  .data-box-att {
    border-right: 2px solid $lighterGray;
    padding: 0.26rem 0.6rem;
    height: max-content;
    transition: border-color 300ms;

    &:hover {
      border-color: $gceDarkBlue;
    }

    .data-att {
      font-size: 0.9rem;
      margin: 0.3rem 0 0;
      display: inline-block;

      &.ltr {
        direction: ltr;
      }

      .attachment-path-att {
        border: 1px solid lightgray;
        margin: 4px 0 4px 4px;
        padding: 4px;
        cursor: pointer;
        border-radius: 4px;
        width: 100%;
        text-align: right;
        &:hover {
          color: black;
        }
      }
    }
  }

  .data-box {
    border-right: 2px solid $lighterGray;
    padding: 0.26rem 0.6rem;
    height: max-content;
    transition: border-color 300ms;
    margin: 0.5rem;
    margin-right: 0;
    .image-margin {
      margin-top: 0.5rem;
    }

    &:hover {
      border-color: $gceDarkBlue;
    }

    .title-container {
      display: flex;
      align-items: baseline;
      color: $gceMutedBlue;

      .icon {
        margin: 0 0 0 0.4rem;
        font-size: 0.9rem;
      }

      .title {
        font-size: 1rem;
        margin: 0;
        display: inline-block;
      }
    }

    .data {
      font-size: 1rem;
      margin: 0.3rem 0 0;
      display: inline-block;
      white-space: pre-wrap;
      text-align: justify;
    }
  }

  .no-border {
    border: none;
  }

  .no-padding {
    padding: 0;
  }
}
