@import '../../../assets/styles/variables.scss';

.expenses-wrapper {
  padding: 1rem;

  .table-row {
    height: 30px;
    padding: 0%;
    margin: 0%;
  }

  .cash-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .flex-input {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    flex: 1;
  }

  .flex-data-boxes {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 5px;
  }
  .flex-center {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    > span {
      margin-left: 10px;
    }
  }
  .cards-table {
    .ui.table tr td {
      border-top: 0px !important;
    }
    .ui.compact.table td {
      padding: 0.3em 0.7em !important;
    }
  }
}
.expenses-form-view {
  @media print {
    * {
      overflow: visible !important;
    }
  }

  .header {
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    .main-header-title {
      font-weight: bold;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .header-logo {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .title-container-att {
    display: flex;
    align-items: baseline;
    color: $gceMutedBlue;

    .title-att {
      margin: 0 0 0.78rem 0;
      display: inline-block;
    }
  }

  .data-box-att {
    border-right: 2px solid $lighterGray;
    padding: 0.26rem 0.6rem;
    height: max-content;
    transition: border-color 300ms;

    &:hover {
      border-color: $gceDarkBlue;
    }

    .data-att {
      font-size: 0.9rem;
      margin: 0.3rem 0 0;
      display: inline-block;

      &.ltr {
        direction: ltr;
      }

      .attachment-path-att {
        border: 1px solid lightgray;
        margin: 4px 0 4px 4px;
        padding: 4px;
        cursor: pointer;
        border-radius: 4px;
        width: 100%;
        text-align: right;
        &:hover {
          color: black;
        }
      }
    }
  }

  .new-box-data {
    display: flex;
    flex-direction: row;
    color: $gceMutedBlue;
    align-content: center;
    align-items: center;
    font-size: 0.8rem;

    .new-title {
      font-size: 0.7rem !important;
      margin: 0;
      padding: 0px 5px;
    }

    .data-value {
      font-size: 0.7rem !important;
      word-break: break-word;
      text-align: right;
      margin: 0;
    }

    .image-margin {
      margin-top: 0.5rem;
    }
  }

  .data-box-footer {
    border-right: 2px solid $lighterGray;
    padding: 0.26rem 0.6rem;
    height: max-content;
    transition: border-color 300ms;
    margin: 0.5rem;
    margin-right: 0;
    display: flex;
    align-items: baseline;

    &:hover {
      border-color: $gceDarkBlue;
    }

    .icon-footer {
      margin: 0 0 0 0.4rem;
      font-size: 0.9rem;
      color: $gceMutedBlue;
    }

    .title-footer {
      font-size: 1rem;
      margin: 0;
      display: inline-block;
      color: $gceMutedBlue;
    }
  }

  .order-title {
    font-weight: bold;
    font-size: 0.9rem;
    line-height: 1.5rem;
  }
  .order-data {
    padding: 1rem 1rem 0.3rem 1rem;
  }

  .main-title {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 0.9rem;
    line-height: 1.5rem;
  }
}
