.accept-reject-expense-modal{
  .flex-input {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-wrap: wrap;
    gap: 0.5rem;
    flex: 1;

    div {
     flex :1;
     width: 100%
    }
  }

  .flex-column-gap-1 {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .bold-1{
    font-weight: bold !important;
  }
}