@import './../../../assets/styles/variables.scss';

.contract-assets-page {
  .no-data-footer {
    margin: 1rem;
  }

  .remove-btn {
    &:hover {
      cursor: pointer;
    }
  }
}
