@import './../../assets//styles/variables.scss';

.page-card-container {
  background-color: white;
  border: 1px solid white;
  border-radius: 10px;
  padding: 1rem;
  height: calc(100vh - 62px - 2rem);
  overflow-y: auto;
  overflow-x: hidden;
}
