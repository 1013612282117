@import './../../../assets/styles/variables.scss';

.merchandise-table-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ui.button {
    width: 100%;
  }

  .toggle-bar {
    margin: 0.5rem;

    .ui.toggle.checkbox label {
      margin-left: 7rem !important;
      font-weight: bold;
    }

    .ui.toggle.checkbox input:focus:checked ~ .box:before,
    .ui.toggle.checkbox input:focus:checked ~ label:before,
    .ui.toggle.checkbox input:checked ~ .box:before,
    .ui.toggle.checkbox input:checked ~ label:before {
      background-color: $gceSkyBlue !important;
    }
    .ui.toggle.checkbox input:checked ~ .box:after,
    .ui.toggle.checkbox input:checked ~ label:after {
      left: 2rem;
    }
    .ui.checkbox input:checked ~ .box:after,
    .ui.checkbox input:checked ~ label:after {
      margin-right: 14rem;
    }

    .ui.toggle.checkbox .box:before,
    .ui.toggle.checkbox label:before {
      width: 6rem;
      height: 30px;
      margin-right: 10rem;
    }

    .ui.toggle.checkbox .box:after,
    .ui.toggle.checkbox label:after {
      width: 30px;
      height: 30px;
      margin-right: 10rem;
      transition: all 0.3s ease, left 0.3s ease !important;
    }
  }
}
