@import './../../../assets/styles/variables.scss';

.edit-grid {
  margin: 0.5rem !important;
  border-radius: 10px;
  border: 1px solid $lighterGray;
}

.maintenance-form-view {
  .header-wrapper {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    .ui.button > .icon:not(.button) {
      opacity: 1;
      margin: 0;
      padding: 0;
    }
    .ui.button:not(.icon) > .icon:not(.button):not(.dropdown) {
      margin: 0;
    }
  }
  .segments-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    .single-segment-wrapper {
      flex-grow: 1;
      white-space: nowrap;
    }
  }
  .full-width {
    width: 100%;
  }
  .title-container-att {
    display: flex;
    align-items: baseline;
    color: $gceMutedBlue;

    .icon {
      margin: 0 0 0 0.4rem;
    }

    .title-att {
      margin: 0 0 0.78rem 0;
      display: inline-block;
    }
  }
  .embedded-table {
    .page-card-container {
      padding: 0 !important;
      margin: 0 !important;
      height: 100%;

      .settings-table {
        margin: 0.5rem 0 1rem 0;
      }
    }
  }

  .buttons-container {
    padding: 1rem 0;
  }
  .buttons-container-contents {
    display: flex;
    gap: 0.5rem;
  }

  .fluid-button-margin {
    margin-top: 0.5rem !important;
  }

  .form-columns-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .form-rows-wrapper {
    display: flex;
    flex-direction: column;
  }

  .two-columns {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    .ui.segment {
      margin: 0;
      flex: 1;
    }
    > div {
      flex: 1;
    }
    .vertical-divider {
      width: 1px;
      background-color: rgb(223, 223, 223);
    }

    @media only screen and (max-width: 767px) {
      flex-direction: column;
      gap: 0;
    }
  }

  .data-box-att {
    border-right: 2px solid $lighterGray;
    padding: 0.26rem 0.6rem;
    height: max-content;
    transition: border-color 300ms;

    &:hover {
      border-color: $gceDarkBlue;
    }

    .data-att {
      font-size: 0.9rem;
      margin: 0.3rem 0 0;
      display: inline-block;

      &.ltr {
        direction: ltr;
      }

      .attachment-path-att {
        border: 1px solid lightgray;
        margin: 4px 0 4px 4px;
        padding: 4px;
        cursor: pointer;
        border-radius: 4px;
        width: 100%;
        text-align: right;
        &:hover {
          color: black;
        }
      }
    }
  }

  .data-box {
    border-right: 2px solid $lighterGray;
    padding: 0.26rem 0.6rem;
    height: max-content;
    transition: border-color 300ms;
    margin: 0.5rem;
    margin-right: 0;
    .image-margin {
      margin-top: 0.5rem;
    }

    &:hover {
      border-color: $gceDarkBlue;
    }

    .title-container {
      display: flex;
      align-items: baseline;
      color: $gceMutedBlue;

      .icon {
        margin: 0 0 0 0.4rem;
        font-size: 0.9rem;
      }

      .title {
        font-size: 1rem;
        margin: 0;
        display: inline-block;
      }
    }

    .data {
      font-size: 1rem;
      margin: 0.3rem 0 0;
      display: inline-block;
      white-space: pre-wrap;
      text-align: justify;
    }
  }

  .no-border {
    border: none;
  }

  .no-padding {
    padding: 0;
  }
}

.add-maintenance-form,
.edit-maintenance-form {
  .signature-preview-div {
    p {
      font-weight: bold;
      font-size: 0.92857143em;
    }
    .signature-preview {
      border: 1px solid gray;
      border-radius: 10px;
      transition: ease-in-out 300ms;
      &:hover {
        cursor: pointer;
        box-shadow: 3px 3px 15px -5px rgba(0, 0, 0, 0.2);
      }

      .ui.medium.image,
      .ui.medium.images .image,
      .ui.medium.images img,
      .ui.medium.images svg {
        padding: 3rem;
      }
    }
  }
}

.signature-dimmer.ui.dimmer > .content {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  .signature-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .signature-header {
      padding: 1rem;
      font-size: 1.1rem;
      font-weight: bold;
      color: black;
      border-bottom: 1px solid lightGray;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    .signature-actions {
      padding: 1rem;
      border-top: 1px solid lightGray;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    .signature-canvas {
      background: $lightererGray;
      width: 100%;
      height: 100%;
    }
  }
}
