@import '../../assets/styles/variables.scss';

.playback-page-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  .right-panel-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 1rem;
    border-radius: 5px;

    .title-style {
      border-bottom: 1px solid black;
    }
    .options-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .date-pickers-container {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        .date-picker-lbl-container {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          padding-bottom: 10px;
          .react-datepicker-wrapper {
            .react-datepicker__input-container {
              input {
                width: 100%;
                text-align: center;
                border: 1px solid #bebebe;
                padding-block: 0.5rem;
                background-color: #f5f5f5;
                border-radius: 0.3rem;
              }
            }
          }
        }
        .ui.button .execute-btn Button {
          background-color: #00295d;
          color: white;
        }
      }

      .user-info-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        h2,
        h4,
        h3 {
          margin: 0 !important;
          padding: 0 !important;
        }
      }

      .location-slider-lbl {
        padding: 0 !important;
        margin: 0 !important;
      }
    }
  }

  .map-points-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    height: 100%;
    .map-container {
      height: calc(100vh - 21rem);
      background-color: white;
      padding: 1rem;
      border-radius: 5px;
    }
    .technician-locations-info-container {
      background-color: white;
      border-radius: 5px;
      padding: 0.7rem;
      height: 14rem;
      .technician-locations-info-header {
        background-color: #00295d;
        color: white;
        padding: 0.5rem;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      .technician-locations-info-box-container {
        .locations-info-box-container {
          display: flex;
          flex: 1;
          flex-direction: column;
          border-left: 1px solid #d3d3d3;

          .locations-info-box-headers-container {
            display: flex;
            color: #294258;
            background-color: #ccddf5;
            border-bottom: 1px solid #d3d3d3;
            padding: 4px 0px;
            text-align: center;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            .locations-info-box-headers-time {
              width: 15rem;
            }

            .locations-info-box-headers-address {
              flex: 1;
            }
          }
          .locations-info-box {
            width: 100%;
            height: 100px;

            .location-info-row-container {
              display: flex;
              font-size: 15px !important;
              border-bottom: 1px solid #d3d3d3;
              align-items: center;
              text-align: center;
              .location-info-row-address {
                height: 40px;
                flex: 1;
                width: 10rem;
                img {
                  height: 30px;
                  cursor: pointer;
                }
              }

              .location-info-row-time {
                display: flex;
                flex-direction: row;
                gap: 1rem;
                width: 14rem;
              }
            }
            .yellow-back-ground {
              background-color: #fcf874;
              border-left: 1px solid #d3d3d3;
            }
          }
        }
      }
    }
  }
}

.address-text {
  position: absolute;
  bottom: 10rem;
  width: 250px;
  padding: 10px;
  left: 49%;
  z-index: 1800;
  background-color: #fff;
  box-shadow: 0 0 28px 2px #777;
  border-radius: 10px/40px;
}

.ReactVirtualized__List {
  outline: none !important;
}
