@import '../../../assets/styles/variables.scss';

.ticket-comments-list {
  .list-title {
    margin-bottom: 1.6rem;
  }

  .overflow-container {
    overflow: auto;
    padding: 0.4rem;

    .ticket-comments-list-item {
      display: flex;
      margin-bottom: 0.4rem;

      .chat-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-left: 1rem;

        .profile-img {
          height: 2.6rem;
          width: 2.6rem;
          object-fit: cover;
          border-radius: 1.3rem;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
        }

        .vertical-line {
          flex: 1;
          width: 1px;
          margin-bottom: -0.4rem;
          background-color: $lighterGray;
        }
      }

      .comment-content {
        padding: 0.6rem 1rem;
        background-color: $lightererGray;
        width: 100%;
        border-radius: 20px 4px 4px 20px;

        .comment-header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .user-name-action-container {
            display: flex;
            align-items: baseline;

            .user-name {
              margin: 0;
            }

            .action-type {
              margin: 0;
              color: $lightGray;
              margin-right: 0.5rem;
              font-weight: bold;
              font-size: 0.8rem;
            }
          }

          .time {
            margin: 0.2rem 0 0;
            direction: ltr;
            font-size: 0.8rem;
            font-weight: bold;
            text-align: right;
            color: $lightGray;
          }
        }

        .comment-body {
          margin: 0.5rem 0;
        }
      }

      &:last-child {
        .chat-image-container {
          .vertical-line {
            background-color: unset;
          }
        }

        .comment-content {
          border-radius: 20px 4px 20px 20px;
        }
      }

      &:first-child {
        .comment-content {
          border-radius: 20px 20px 4px 20px;
        }
      }
    }
  }
}
