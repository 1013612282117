@import './../../../assets/styles/variables.scss';

.user-details {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .user-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1.5rem 3rem;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 24px;
    max-height: calc(100vh - 62px - 4rem);
    overflow-y: auto;

    .header-img {
      margin-left: 2rem;
      .image-cropper {
        width: 200px;
        height: 200px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
        margin: auto;
        .img {
          object-fit: cover;
          min-height: 100%;
        }
      }
    }

    .contents {
      text-align: right;
      font-size: 1.1rem;
      margin-right: 2rem;
      h2 {
        text-align: center;
      }
      hr {
        margin: 2rem 0;
        border: 0;
        height: 1px;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
      }
      b {
        color: rgb(73, 73, 73);
      }
    }
    @media only screen and (max-width: 767px) {
      // flex-direction: column;
      .header-img {
        margin: auto;
      }

      .contents {
        margin: 1rem auto auto auto;
      }
    }
  }
}
