.add-sys-ticket-form,
.add-asset-ticket-form {
  .date-input-ltr {
    input {
      direction: ltr !important;
    }
  }

  .field.disabled,
  .field .ui.disabled {
    opacity: 1 !important;
    color: grey;
    cursor: not-allowed;
  }
}
