// sass variables

$gceDarkBlue: #00295d;
$gceBlue: #003e8d;
$gceBlueTransparent: #335b9131;
$gceSkyBlue: #037ec4;
$gceMutedBlue: #627986;

$charcoalBlack: #464646;
$charcoalDark: #474747;
$mutedCharcoal: #6d6d6d;

$charcoalTransparent: rgba(0, 0, 0, 0.384);
$lightGray: rgb(170, 170, 170);
$lighterGray: rgb(225, 225, 225);
$lightereGray: rgb(235, 235, 235);
$lightererGray: rgb(245, 245, 245);

$darkGray: #787878;
$dropdownBg: rgba(238, 238, 238, 0.288);

$red: #b40000;
$green: #009200;
$orange: #e99700;
$yellow: #b4ae7d;
$blue: #0067bf;
