.animated-modal-container {
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-wrapper {
    background-color: white;
    border-radius: 0.8rem;
    padding: 1.5rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);

    .ui.selection.dropdown {
      text-align: right;
    }

    .modal-btns-container {
      margin-top: 1rem;
      display: flex;
      gap: 1rem;

      .button {
        flex: 1;
      }
    }
  }
}
