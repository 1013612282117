@import './../../../assets/styles/variables.scss';

.modal-link-delete {
  padding: 1em 7em;
  margin: auto;
}

.modal-link-add {
  .ui.button {
    margin: auto;
  }
}

.modal-contents {
  padding: 0 3em;
  .modal-table {
    margin: auto !important;
  }
}
