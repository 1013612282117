.maintenance-ticket-container {
  overflow: auto;
  padding: 1.5rem 1rem;

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
  }
  .responsive-cards {
    margin-top: 1rem;
    .ui.card > .content,
    .ui.cards > .card > .content {
      padding: 0.2rem;
      .ui.table tr td {
        border-top: 0px !important;
      }
      .ui.compact.table td {
        padding: 0.3em 0.7em !important;
      }
      .ui.label {
        margin: 0.3rem;
      }

      .responsive-actions {
        padding: 0.3rem;
      }
    }
    .ui.card > .extra,
    .ui.cards > .card > .extra {
      display: flex;
    }
  }
  .title {
    font-size: 0.9rem;
    color: #627986;
    margin: 0 0 0.4rem 0;
  }
  .content {
    margin: 0 0 1.5rem 0;
  }
  .content-date {
    margin: 0 0 1.5rem 0;
    direction: ltr;
    text-align: right;
  }

  .ui.label {
    padding: 0.3rem !important;
  }
  .ui.label > .icon {
    margin: 0 !important;
  }
}

.maintenance-ticket-form {
  .add-device {
    display: flex;
    gap: 0.5rem;
    > div {
      flex-grow: 50;
      margin: 0 !important;
    }

    .ui.icon.button,
    .ui.icon.buttons .button {
      flex-grow: 1;
      margin-top: auto;
    }
    .ui.form .field > .selection.dropdown {
      height: 40px !important;
    }
  }
  .added-tracking-vehicles {
    margin: 1rem auto;
    .ui.cards {
      margin: 1rem auto;
    }
  }
  .ui.red.button,
  .ui.red.buttons .button {
    font-size: 0.5rem !important;
  }
  .responsive-card-wrapper {
    display: flex;
    justify-content: space-between;
    .ui.table tr td {
      border-top: 0px !important;
    }
    .ui.compact.table td {
      padding: 0.3em 0.7em !important;
    }
  }
  .edit-grid {
    text-align: right !important;
  }
}

.date-input-ltr {
  input {
    direction: ltr !important;
  }
}
