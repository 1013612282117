#map {
  width: 100%;
  height: 100%;

  overflow: hidden;

  border-radius: 5px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);

  .ol-viewport {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);

    .ol-zoom-in,
    .ol-zoom-out,
    .ol-zoomslider-thumb,
    .ol-full-screen-false,
    .ol-full-screen-true {
      cursor: pointer;
      outline: none;
      //padding-bottom: 4px;
    }

    .ol-zoomslider {
      top: 2.43rem;
      border-radius: 0;
    }

    .ol-zoom-out {
      margin-top: 204px;
    }

    .ol-attribution.ol-uncollapsible {
      opacity: 0;
    }

    .ol-overlay-container.ol-selectable {
      position: unset !important;
    }
  }

  .layer-switcher {
    top: 0.5rem;
    right: 2.5rem;
    z-index: 1000;

    button {
      background-color: rgba(0, 60, 136, 0.5);
    }

    .panel {
      text-align: right;

      ul {
        padding: 0px;

        li ul {
          padding-top: 0.8rem;

          li {
            display: flex;
            align-items: flex-end;

            input,
            label {
              cursor: pointer;
              flex: 1;
            }
          }
        }
      }
    }
  }

  .ol-mouse-position {
    top: unset;
    bottom: 8px;
    background: rgba(0, 60, 136, 0.3);
    border-radius: 4px;
    padding: 2px 4px;
    color: white;
    font-weight: bold;
    font-size: 12px;
  }

  // tooltip
  #tooltip-container {
    background-color: rgba(255, 255, 255, 0.75);
    border: 1px solid gray;
    display: none;

    z-index: 1001;
    text-align: center;
    position: absolute;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 0.7rem;
    border-radius: 10px;
    margin-left: 7px;
    margin-top: -19px;

    min-width: 260px;

    &:before,
    &:after {
      top: 100%;
      border: solid transparent;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:before {
      border-top-color: rgba(0, 0, 0, 0.527);
      border-width: 11px;
      left: 130px;
      margin-left: -11px;
    }

    &:after {
      border-width: 10px;
      left: 130px;
      margin-left: -10px;
    }
  }
}
