.task-assignment-page-container {
  .title {
    margin: 0 0 2rem 0;
  }

  .task-type-inner-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .add-new-btn {
      .icon {
        margin-left: 0.5rem;
      }

      .icon,
      label {
        cursor: pointer;
      }
    }

    .assign-team-row {
      display: flex;
      align-items: baseline;
      gap: 0.3rem;
      flex-wrap: wrap;
    }
  }
}
