@import './../../../assets//styles/variables.scss';

.table-search {
  width: 100%;
  margin-bottom: 0.4rem;

  .ui.form .field {
    margin: 0 !important;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    .search-input {
      gap: 0.5rem;
      flex: 1;
      display: flex;
      flex-wrap: nowrap;

      > div {
        flex: 1;
      }
    }
    .table-dropdown {
      z-index: 11;
      direction: rtl;
      flex-basis: 30%;
      text-align: right;
      height: 40px;
      @media only screen and (max-width: 600px) {
        flex-basis: 100%;
      }
    }

    .text-box {
      margin: 0;
      @media only screen and (max-width: 600px) {
        width: 10px;
      }
      // margin-left: 0.5rem;
    }

    .ui.button {
      margin: 0;
    }

    .dropdown-search {
      direction: rtl;
      text-align: right;
    }

    .react-datepicker-wrapper {
      // width: 100%;
      // margin-left: 0.5rem;
    }
    .react-datepicker__input-container {
      input {
        height: 40px !important;
      }
      .react-datepicker-ignore-onclickoutside {
        width: 100%;
        flex: 1;
      }
    }
  }

  .tags-wrapper {
    margin: 1rem 0;
    .ui.label,
    .ui.labels .label {
      margin: 0.3rem 0 0.1rem 0.4rem;
    }
  }
}
