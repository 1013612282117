@import './../../assets/styles/variables.scss';

.dashboard {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 1000px;
  margin: auto !important;
  h2 {
    text-align: center;
  }

  .dashboard-headers {
    text-align: center;
    // border-bottom: 2px solid $lighterGray;
    padding: 0.5rem 0;
    width: max-content;
    // margin: 1rem;
    margin-right: 0;
    padding-bottom: 0.5rem;
    transition: color 300ms;

    // &:hover {
    //   border-color: $gceDarkBlue;
    //   color: $gceSkyBlue;
    // }

    @media only screen and (max-width: 767px) {
      font-size: 1rem;
    }
  }

  .pies-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    .pie-container {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  // .table-container {
  //   display: flex;
  //   flex-direction: column;
  //   gap: 1rem;
  // }

  .table-container-2c {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    @media only screen and (max-width: 580px) {
      flex-direction: column;
    }
  }

  .sub-container {
    background-color: white;
    border: 1px solid white;
    border-radius: 10px;
    padding: 1rem;
  }

  .flex-1 {
    flex: 1;
  }

  // .card {
  //   background-color: $lightererGray;
  //   border-radius: 10px;
  //   padding: 1rem;
  //   font-size: 1.2rem;
  //   width: fit-content;
  //   text-align: center;
  //   display: flex;
  //   flex-direction: column;
  //   gap: 1rem;
  //   .card-icon {
  //     background-color: $lightGray;
  //     color: white;
  //     margin: auto;
  //     padding: 1rem;
  //     border-radius: 10px;
  //   }
  //   .card-title {
  //   }
  //   .card-content {
  //     font-weight: bold;
  //   }
  // }
}
