@import './../../../assets/styles/variables.scss';

.team-members-page {
  .remove-icon {
    cursor: pointer;
    transition: transform 300ms;

    &:hover {
      transform: scale(1.3);
    }
  }

  .user-image-container {
    display: flex;
    align-items: center;

    .user-image {
      height: 2.6rem;
      width: 2.6rem;
      object-fit: cover;
      border-radius: 1.3rem;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      margin-left: 1rem;
    }
  }

  .checkbox-div {
    max-height: 200px;
    overflow: auto;
    border: 1px solid $lightererGray;
    border-radius: 10px;
    background-color: $lightererGray;
    padding: 0.5rem;

    .one-checkbox {
      margin: 0.2rem 0;
      font-size: 1.2rem;
    }

    .no-data {
      text-align: center;
      font-size: 1.3rem;
    }
  }
}
