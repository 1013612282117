@import './../../../assets/styles/variables.scss';

.print-container {
  margin: 2rem;
  page-break-before: always;

  .header {
    text-align: center;
    margin: auto;

    .header-title {
      margin: 1rem;
      font-weight: bold;
      font-size: 1.1rem;
    }
  }

  .contents {
    margin: 2rem auto;
    // border: 1px solid $lighterGray;
    // border-radius: 10px;
    padding: 1rem;

    .ltr {
      direction: ltr;
    }

    .segment {
      margin: 2rem;
      page-break-inside: avoid;
    }

    .desc {
      border: 1px solid $lighterGray;
      border-radius: 10px;
      padding: 1rem;
      white-space: pre-wrap;
    }
  }
}
