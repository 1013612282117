.ui.segment.warranty-form {
  .field .ui.icon.input input {
    margin-bottom: 0 !important;
    height: 38px;
  }

  .w-add-btn {
    width: 40px;
    height: 38px;
    margin-right: 0;
  }
}
