.face {
  display: flex;
  height: 5rem;
  justify-content: center;
  padding: 5rem 0 10rem 0;

  .eye {
    background-color: #000;
    border-radius: 50%;
    height: 1.5rem;
    width: 1.5rem;
  }

  .mouth {
    display: flex;
    justify-content: center;
    margin: 1rem 1rem 0 1rem;
    width: 10rem;
  }

  .shock {
    background: #000;
    clip-path: circle(50% at 50% 100%);
    height: 3rem;
    margin-top: 1rem;
    transition: all 0.3s ease-in-out;
    width: 5rem;
  }

  .sad {
    border-color: #000 transparent transparent transparent;
    border-style: solid;
    border-width: 15px;
    border-radius: 100%;
    height: 3rem;
    margin-top: 2rem;
    transition: border-width 0.3s ease-in-out;
    width: 5rem;
  }

  .flat {
    border-bottom: solid 15px #000;
    margin-bottom: 1rem;
    transition: all 0.3s ease-in-out;
    width: 3.5rem;
    margin-top: 2rem;
  }

  .smile {
    border-color: transparent transparent #000 transparent;
    border-style: solid;
    border-width: 15px;
    border-radius: 100%;
    height: 3rem;
    transition: border-width 0.3s ease-in-out;
    width: 5rem;
  }

  .happy {
    background: #000;
    clip-path: circle(50% at 50% 0);
    height: 3rem;
    margin-top: 2rem;
    transition: all 0.3s ease-in-out;
    width: 5rem;
  }
}
