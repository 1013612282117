.not-found {
  text-align: center;
  display: block;
  padding: 1.5rem 1.5rem 1.5rem 2.3rem;

  .header {
    margin: 3rem 0;
    font-size: 3rem;
  }

  .sub-header {
    margin: 3rem 0;
    font-size: 2rem;
  }
}
