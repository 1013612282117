.evaluation-page-container {
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  transition: all 0.3s ease-in-out;
  width: 100vw;
  font-size: 1.3em;

  .header {
    text-align: center;
    h5 {
      margin: 0;
    }
  }

  .go-home-btn {
    margin-top: 30px;
    cursor: pointer;
  }

  .stars-container {
    display: flex;
    justify-content: space-between;
    width: 20rem;
    cursor: pointer;
  }

  .text-area {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    label {
      margin: 2rem 0 0.5rem;
      font-weight: bold;
    }

    textarea {
      width: 100%;
      margin-bottom: 0.5rem;
      padding: 1rem;
    }
  }

  &.face-1 {
    background-image: linear-gradient(0deg, #f7872c, #e65100);
  }

  &.face-2 {
    background-image: linear-gradient(0deg, #fa9b47, #ef6c00);
  }

  &.face-3 {
    background-image: linear-gradient(0deg, #fcb647, #f57f17);
  }

  &.face-4 {
    background-image: linear-gradient(0deg, #facc59, #f9a825);
  }

  &.face-5 {
    background-image: linear-gradient(0deg, #f8de6b, #fbc02d);
  }
}
.date-pickers-container {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;

  .date-picker-lbl-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 10px;
    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          width: 100%;
          text-align: center;
          border: 1px solid #bebebe;
          padding-block: 0.5rem;
          background-color: #f5f5f5;
          border-radius: 0.3rem;
        }
      }
    }
  }
  .ui.button .execute-btn Button {
    background-color: #00295d;
    color: white;
  }
}