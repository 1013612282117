@import './../../assets/styles/variables.scss';

.search-wrapper {
  padding: 1rem;
  display: flex;

  .user-name-search {
    flex: 1;
    input {
      padding: 0.5rem !important;
    }
  }

  .advanced-search-wrapper {
    position: relative;

    .ui.button {
      height: 3rem;
    }

    .search-container {
      position: absolute;
      top: 46px;
      right: 8px;
      list-style: none;
      margin: 0;
      padding: 0;
      background-color: white;
      border-radius: 10px;
      box-shadow: 0 0 6px rgba($color: #000000, $alpha: 0.3);
      white-space: nowrap;
      padding: 1rem;
      width: 40rem;
      height: 24rem;
      z-index: 1;

      &::before {
        content: '';
        border: 7px solid transparent;
        border-bottom-color: #dededf;
        position: absolute;
        top: -16px;
        right: 12px;
        z-index: 3;
      }

      &::after {
        content: '';
        border: 6px solid transparent;
        border-bottom-color: white;
        position: absolute;
        top: -12px;
        right: 13px;
        z-index: 3;
      }

      .search-container-title {
        display: flex;
        justify-content: space-between;

        h5 {
          margin: 0;
        }

        i.icon {
          color: $lightGray;
          cursor: pointer;

          &:hover {
            color: #000000;
          }
        }
      }

      .form-body-container {
        direction: ltr;
        margin-right: -1rem;
        padding-right: 0.95rem;
        padding-left: 1rem;
        max-height: 28rem;
        overflow-y: auto;

        &::-webkit-scrollbar-track {
          background: rgb(245, 245, 245);
          border-radius: 5px;
        }
        &::-webkit-scrollbar {
          width: 5px;
        }
        &::-webkit-scrollbar-thumb:vertical {
          background-color: rgba(156, 156, 156, 0.25);
        }
        &:hover::-webkit-scrollbar-thumb:vertical {
          background-color: rgba(156, 156, 156, 0.719);
        }
        &::-webkit-scrollbar-thumb:vertical:active {
          background-color: rgb(156, 156, 156);
        }

        .rtl {
          direction: rtl;

          .flex-columns {
            display: flex;
            flex-direction: row;
            gap: 1rem;
            margin-top: 0.5rem;

            .grouped.fields {
              width: 100% !important;
            }
          }

          .oneLineTextInput {
            display: flex;
            align-items: center;

            label {
              margin-left: 1rem;
              padding-bottom: 5px;
              font-weight: bold;
            }

            input {
              height: 2.5rem;
              font-size: 0.9rem;
              width: 6rem;
              padding-right: 0.7rem;
              border: 1px solid rgba(34, 36, 38, 0.15) !important;
              border-radius: 5px !important;
            }

            .remove-icon {
              position: absolute;
              left: 0;
              top: -3px;
              font-size: 0.85rem;
              background-color: unset;
            }
          }
        }
      }
    }
  }
}
