@import './../../assets//styles/variables.scss';

.full-top-navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  background: linear-gradient(-87deg, #00295d, #005d92);
  color: #fff;
  padding: 10px;

  .vertical-divider {
    width: 1px;
    height: 40px;
    background-color: #4087a2;
    margin: 0 1.5rem 0 1rem;
  }

  .notification-badge {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2498da !important;
    cursor: pointer;

    i {
      margin: 0;
      font-size: 1.25rem;
    }
  }
}

.user-badge-container {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  direction: rtl;
  font-size: 14px;

  @media (max-width: 450px) {
    font-size: 12px;
  }

  @media (max-width: 385px) {
    font-size: 10px;
  }

  .user-img {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    object-fit: cover;
    margin-left: 1em;
  }

  .name-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    h5 {
      margin: 0;
      color: $lightererGray;
      font-size: 1em;
    }

    p {
      margin: 0;
      color: $lighterGray;
      font-size: 0.9em;
    }
  }

  .icon.angle {
    margin: 0 0 0 0.4em;
  }

  .user-navbar-menu-container {
    position: absolute;
    right: 0;
    top: 45px;
    background-color: white;
    border-radius: 4px;
    color: black;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    border: rgb(131, 131, 131);
    z-index: 10000;

    &::after {
      content: '';
      border: 5px solid transparent;
      border-bottom-color: white;
      position: absolute;
      top: -10px;
      right: 8px;
      z-index: 3;
      box-shadow: 0 -3px 8px -3px rgba(0, 0, 0, 0.3);
    }

    .menu-item {
      display: flex;
      gap: 1em;
      padding: 0.8em 1em;
      border-radius: 4px;

      &:hover {
        background-color: rgb(238, 238, 238);
      }

      &:first-child {
        padding-top: 1em;
      }

      &:last-child {
        padding-bottom: 1em;
      }

      h5 {
        margin: 0;
      }
    }
  }
}
