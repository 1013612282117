@import './../../assets/styles/variables.scss';

.user-map-container {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  .user-map-list-container {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: white;
    white-space: nowrap;
    border-radius: 5px;

    .user-cards-list {
      height: calc(100vh - 60px - 2rem - 2rem - 45px);
      overflow-y: auto;

      .no-data {
        text-align: center;
        margin-top: 3rem;
        font-size: 1.5rem;
      }
    }

    .user-card-wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      border: 1px solid rgb(223, 223, 223);
      padding: 1rem;
      margin: 0.5rem;
      cursor: pointer;

      .header {
        display: flex;
        align-items: center;
        gap: 0.8rem;

        .header-info {
          display: flex;
          gap: 1rem;
          width: 100%;
          font-weight: bold;
          gap: 1rem;
        }

        img {
          width: 50px;
          border-radius: 25px;
        }

        // .ui.circular.image,
        // .ui.circular.image > *,
        // .ui.circular.images .image,
        // .ui.circular.images .image > * {
        //   width: 50px !important;
        // }
      }

      .show-button {
        width: 100%;
        height: fit-content;
        display: flex;  
        justify-content: space-around;
        border:1px solid lightgray;
        border-radius: 10px;
        padding: 0.5rem;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
        .icon-container:not(:last-child) {
          border-left: 1px solid lightgray;
        }

        .icon-container{
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .gpsDate {
        display: flex;
        gap: 0.5rem;
        color: $darkGray;
      }

      .icon-shared-color {
        font-size: 1.5rem;
        color: $blue !important;
        cursor: pointer;

        &:hover {
          color: $gceSkyBlue !important;
        }
      }
    }
  }

  // @media (max-width: 1000px) {
  //   .user-map-list-container {
  //     width: 50%;
  //   }
  // }
}
