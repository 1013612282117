@import './../../../assets//styles/variables.scss';

.settings-table {
  margin: 0 1rem;

  .ui.cards {
    margin: 0 -0.5em !important;
  }

  .no-data {
    text-align: center;
    margin-top: 3rem;
    font-size: 1.5rem;
  }

  .table-loader {
    margin-top: 5rem !important;
  }
  .cards-table {
    .ui.table tr td {
      border-top: 0px !important;
    }
    .ui.compact.table td {
      padding: 0.3em 0.7em !important;
    }
  }

  .footer-card-pagination {
    gap: 1rem;
    text-align: center;
    display: flex;
    flex-direction: column;

    .ui.pagination.menu {
      margin: auto;
    }
    @media (max-width: 510px) {
      .ui.pagination.menu .item {
        min-width: auto;
      }

      .ui.menu .item {
        padding: 0.5rem !important;
      }
    }

    .responsive-pages-dropdown {
      gap: 0.5rem;
      display: flex;
      align-items: center;
      margin: auto;
      .ui.selection.dropdown {
        min-width: 5rem;
      }
    }
  }

  .footer {
    display: flex;
    gap: 1rem;
    color: gray;
    justify-content: space-between;
    .pages-dropdown {
      gap: 0.5rem;
      display: flex;
      align-items: center;
      .ui.selection.dropdown {
        min-width: 5rem;
      }
    }
  }
}
