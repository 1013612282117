@import '../../../assets/styles/variables.scss';

.tickets-list {
  padding: 0 1rem;
  position: relative;

  .ticket-card {
    position: relative;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
    margin-bottom: 0.8rem;

    &:hover {
      transform: scale(1.01);
      box-shadow: 0 1px 12px 0 rgba(34, 36, 38, 0.25) !important;
    }

    .title {
      font-size: 0.9rem;
      margin: 0.2rem 0 1rem;
    }

    .cutomer-ticket-title {
      display: flex;
      justify-content: space-between;

      .customer {
        margin: 0;
        font-size: 0.8rem;
      }

      .ticket-id {
        margin: 0;
      }
    }

    .ticket-body {
      padding: 0.5rem 0 0;
      display: flex;
      justify-content: space-between;

      .assignee-thump {
        height: 2rem;
        width: 2rem;
        object-fit: cover;
        border-radius: 1rem;
      }
    }

    .caller-name-section {
      display: flex;
      align-items: center;

      i.icon {
        margin: 0 0 0 0.3rem;
      }

      label {
        font-size: 0.8rem;
        text-align: right;
      }
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin: 0.4rem -1rem -1rem;
      padding: 0.3rem 1rem 0.4rem;
      border-radius: 0 0 4px 4px;
      border-top: 1px solid $lighterGray;

      p {
        margin: 0;
        display: inline;
        font-size: 0.9rem;
      }

      i.icon {
        margin: 0 0 0 0.3rem;
      }

      span {
        direction: ltr !important;

        label {
          font-size: 0.8rem;
        }
      }
    }
  }
}
